<template>
  <div class="container">
    <div style="padding: 0px !important" class="col-md-12">
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
        ref="table"
        :tbody-tr-class="'transparentTr'"
        :fields="fields"
        :items="itemsProvider"
      show-empty
      >
         <template #empty>
           <div
                v-if="processing"
                style="color: #55904e"
                class="text-center my-2"
              >
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
              <div class="text-center" v-else>No Properties Found</div>
         </template>
        <template #table-busy>
          <div style="color: #55904e" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(custom)="row">
          <simple-list-property
            :key="row.item.id"
            :property="row.item"
          >
          <template #descriptionTitle="{ property }">
                {{
                property.description
                  ? property.description[0]
                    ? `${sliceString(property.description[0].value[Object.keys(property.description[0].value)[0]])}`
                    : ""
                  : ""
              }}<router-link v-if="(property.description? (property.description[0] ? property.description[0].value[Object.keys(property.description[0].value)[0]] : ''): '').length > 394" :to="`/property-details/${property.id}`">...more</router-link>
            </template>
          </simple-list-property>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
const SimpleListProperty = () => import(/* webpackChunkName: "w46qZWtNPD"*/"../components/simple-list-property");
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      rows: 0,
      isSortDesc: true,
      sortOrder: "desc",
      sortItem: "id",
      isBusy: false,
      currentPage: 1,
      perPage: this.showPerPage,
      fields: [
        {
          key: "custom",
          thClass: ["d-none"],
          tdClass: "remove-border",
        },
      ],
    };
  },
  props: ["properties", "selectPerPage", "sorting", "processing", "showPerPage"],
  watch: {
    sorting(){
      this.$refs.table.refresh()
    },
    showPerPage(val){
      this.perPage = val
    },
    properties(val) {
      this.rows = val.length;
      this.$refs.table.refresh();
    },
    selectPerPage(val) {
      if (val !== this.perPage) {
        this.perPage = val;
      }
    },
  },
  methods:{
    sliceString(str) {
      if (str) {
        if (str.length > 280) {
          this.shouldShow = true;
          return str.slice(0, 280);
        } else return str;
      } else return "";
    },
        itemsProvider(ctx, callback){
            let offset = (this.currentPage - 1) * this.perPage;
            let items = _.orderBy(this.properties.slice(offset, this.perPage * this.currentPage), [this.sorting.split('|')[0]], [this.sorting.split('|')[1]]) || []
            this.$emit('total', items.length)
            return items
        }
    },
  computed:{
    ...mapGetters({
      propertiesWithPagination: "Property/getProperties",
    }),
  },
  mounted() {
  },
  components: {
    SimpleListProperty,
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>

@media (min-width:300px) and  (max-width:700px){
  .span-inline-box{
  width: 300px !important;
}
.span-inlinebox{
      width: 269px !important;
}
}

div >>> .page-item.active .page-link {
  background-color: #55904e;
  border-color: #55904e;
}
div >>> .page-link {
  color: #55904e;
}
div >>> .remove-border {
  border-top: none;
  padding: 0px;
}
.transparentTr {
  background-color: transparent !important;
}
</style>
